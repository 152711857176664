import React, { useEffect, useRef } from 'react'
import tw, { styled } from 'twin.macro'
import EmfImage from '@elements/EmfImage'

const ImageWrap = tw.div`w-full h-full`
const RatioWrap = tw.div`relative w-full`
const Box = styled.div`
	${tw`absolute max-w-[38%] lg:max-w-screen-xs z-10 right-16 lg:right-spacingXl-lg w-full cursor-pointer`}
	bottom: 180px;
	@media screen and (min-width: 1024px) {
		bottom: 20px;
	} ;
`

const ImageBox = ({ data, onClick }) => {
	const ref = useRef(null)
	useEffect(() => {
		const currentRef = ref.current
		const stopPropagation = (e) => e.stopPropagation()
		currentRef.addEventListener('touchmove', stopPropagation)
		return () => currentRef.removeEventListener('touchmove', stopPropagation)
	}, [])

	return (
		<Box onClick={onClick} ref={ref}>
			<ImageWrap>
				<RatioWrap className='z-0 aspect-w-16 aspect-h-9'>
					<EmfImage src={data.localFile} altText={data.altText} />
				</RatioWrap>
			</ImageWrap>
		</Box>
	)
}
export default ImageBox
